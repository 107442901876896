<template>
  <div>

    <!-- Mobile -->
    <div class=" d-sm-none ">
      <table class="table table-striped text-left">
        <thead class=" ">
          <tr>
            <th v-text=" $t('dashboard.stores.title') "> </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for=" (store,index ) in storeList " :key=" index " :class=" store.active ? '' : 'item-offline'">
            <td>
              <div class="row align-items-center ">
                <div class="col-auto ">
                  <img v-image=" fnGetEcommerceLogo( logoList , store.ecommerce ) " class=" img-contain-40 bg-white ">
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col-12">
                      <p class="">
                        <small>
                          <span v-text="'ID:' "> </span>
                          <span v-text=" store.store_id "> </span>
                        </small>
                        <br>
                        <span class="f-w-500" v-text=" $t('tables.name') +': ' ">
                        </span>
                        <span v-text=" (store.name)? store.name : fnStoreURL(store.url) ">
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Desktop -->
    <div class=" d-none d-sm-block table-responsive">
      <table class="table table-sm table-striped text-center ">
        <thead>
          <tr>
            <th> </th>
            <th class=" text-left col " v-text=" $t('dashboard.stores.storeName') ">
            </th>
            <th v-text=" $t('dashboard.stores.phone') "> </th>
            <th v-text=" $t('dashboard.stores.status') "> </th>
          </tr>
        </thead>
        <tbody>

          <tr v-for=" (store,index ) in storeList " :key=" index ">

            <td class="border-none">
              <img v-image=" fnGetEcommerceLogo( logoList , store.ecommerce ) " class=" img-20 ">
            </td>

            <td class=" text-left ">
              <p class=" m-0 ">
                <small>
                  <span v-text="'ID:' "> </span>
                  <span v-text=" store.store_id "> </span>
                </small>
              </p>
              <div>
                <p class="text-truncate">
                  <strong class="f-w-600" v-text=" (store.name)? store.name : fnStoreURL(store.url) ">
                  </strong>
                </p>
              </div>
            </td>

            <td>
              <p v-text=" (store.phone)? store.phone : $t('general.none')  "
                :class=" (store.phone)? '' : 'text-muted' ">
              </p>
            </td>

            <td>
              <p :class="store.active ? 'text-success' : 'text-warning' "
                v-text=" store.active ? $t('dashboard.product.active') : $t('dashboard.product.unactive') ">
              </p>
            </td>
          </tr>

        </tbody>
      </table>
    </div>

  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  export default {
    computed: {
      ...mapState('stores', ['storeList', 'logoList', 'ecommerceList']),
    },
  }
</script>

<style>

</style>